<template>
	<div id="deviceConfig">
		<div class="c-container-box">
			<!-- 设备列表 -->
			<div v-show="!isConfigInfo">
				<el-table :data="dataArr">
					<el-table-column prop="name" label="设备名称" show-overflow-tooltip>
						<template slot-scope="scope">
							<span class="c-clickable-cell" @click="viewConfig(scope.row)">{{scope.row.name}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="uid" label="设备UID"></el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-tooltip content="查看配置" placement="bottom">
								<el-button type="primary" icon="el-icon-document" circle size="mini"
									@click="viewConfig(scope.row)"></el-button>
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination background :page-sizes="[15, 20, 25, 30]" :page-size="10"
					layout="total, sizes, prev, pager, next, jumper" :total="30" v-show="dataArr.length>0">
				</el-pagination>
			</div>
			<!-- 配置详情 -->
			<el-link icon="el-icon-back" type="primary" v-show="isConfigInfo" @click="isConfigInfo=false">返回
			</el-link>
			<div class="config-info" v-show="isConfigInfo">
				<!-- IO输出联动 -->
				<div class="module">
					<div class="title">
						<i class="iconfont icon_dot"></i>
						<span>IO输出联动</span>
					</div>
					<div class="data">
						<el-radio v-model="ioInput" label="1">超标</el-radio>
						<el-radio v-model="ioInput" label="2">LED屏定时开关</el-radio>
					</div>
				</div>
				<!-- LED屏 -->
				<div class="module">
					<div class="title">
						<i class="iconfont icon_dot"></i>
						<span>LED屏</span>
						<el-tooltip content="编辑" placement="bottom" style="margin-left: 20px; color: inherit;">
							<i class="el-icon-edit" @click="showLedDialog"></i>
						</el-tooltip>
					</div>
					<div class="data data-led">
						<label>上报间隔（秒）：<span>120</span></label>
						<label>LED屏宽：<span>3</span></label>
						<label>LED屏高：<span>6</span></label>
						<label>智能TSP：<span>1.2</span></label>
						<label>开始时间：<span>16:10</span></label>
						<label>结束时间：<span>17:00</span></label>
					</div>
				</div>
				<!-- 超标配置 -->
				<div class="module">
					<div class="title">
						<i class="iconfont icon_dot"></i>
						<span>超标配置</span>
					</div>
					<div class="data">
						<el-table :data="sensorArr" border>
							<el-table-column prop="name" label="传感器" show-overflow-tooltip>
								<template slot-scope="scope">
									<span class="c-clickable-cell" @click="showLimitDialog">{{scope.row.title}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="value" label="LED屏显"></el-table-column>
							<el-table-column prop="value" label="下限"></el-table-column>
							<el-table-column prop="value" label="上限"></el-table-column>
							<el-table-column prop="value" label="IO报警"></el-table-column>
							<el-table-column prop="value" label="信号丢失报警"></el-table-column>
							<el-table-column prop="value" label="数据修正"></el-table-column>
							<el-table-column prop="value" label="离线数据(幅度值)"></el-table-column>
							<el-table-column label="操作">
								<template slot-scope="scope">
									<el-tooltip content="编辑" placement="bottom">
										<el-button type="primary" icon="el-icon-edit" circle size="mini"
											@click="showLimitDialog()"></el-button>
									</el-tooltip>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</div>
		</div>
		<!-- led配置 -->
		<Dialog :data="ledDialog">
			<template>
				<div class="led-dialog">
					<div class="c-item">
						<span>上报间隔（秒）：</span>
						<el-input type="number"></el-input>
					</div>
					<div class="c-item">
						<span>LED屏宽：</span>
						<el-input type="number"></el-input>
					</div>
					<div class="c-item">
						<span>LED屏高：</span>
						<el-input type="number"></el-input>
					</div>
					<div class="c-item">
						<span>智能TSP：</span>
						<el-input type="number"></el-input>
					</div>
					<div class="c-item">
						<span>开始时间：</span>
						<el-time-picker format="HH:mm"></el-time-picker>
					</div>
					<div class="c-item">
						<span>结束时间：</span>
						<el-time-picker format="HH:mm"></el-time-picker>
					</div>
				</div>
			</template>
		</Dialog>
		<!-- 超标配置 -->
		<Dialog :data="limitDialog">
			<template>
				<div class="limit-dialog">
					<div class="item">
						<el-checkbox>LED屏</el-checkbox>
						<el-checkbox>IO报警</el-checkbox>
						<el-checkbox>信号丢失报警</el-checkbox>
					</div>
					<div class="item">
						<div class="item-limit">
							<el-checkbox>下限报警</el-checkbox>
							<span>数值：</span>
							<el-input type="number"></el-input>
						</div>
						<div class="item-limit">
							<el-checkbox>上限报警</el-checkbox>
							<span>数值：</span>
							<el-input type="number"></el-input>
						</div>
					</div>
					<div class="item">
						<div class="c-item">
							<span>数据修正<br />[0~100]：</span>
							<el-input type="number"></el-input>
						</div>
						<div class="c-item">
							<span>离线数据：</span>
							<el-input type="number"></el-input>
						</div>
						<div class="c-item">
							<span>幅度值：</span>
							<el-input type="number"></el-input>
						</div>
					</div>
				</div>
			</template>
		</Dialog>
	</div>
</template>

<script>
	import Dialog from '../../components/Dialog.vue';

	export default {
		data() {
			return {
				sensorArr: [],
				dataArr: [],
				isConfigInfo: false,
				ioInput: '2', //io输出联动
				// led配置
				ledDialog: {
					show: false,
					title: 'LED屏配置',
					width: '500px'
				},
				// 超标配置
				limitDialog: {
					show: false,
					title: '超标配置',
					width: '500px'
				}
			}
		},
		components: {
			Dialog
		},
		methods: {
			// 查看配置
			viewConfig(row) {
				this.isConfigInfo = true;
			},
			// 显示led配置
			showLedDialog() {
				this.ledDialog.show = true;
			},
			// 显示超标配置
			showLimitDialog() {
				this.limitDialog.show = true;
			}
		},
		mounted() {
			this.sensorArr = this.common.cGetDeviceData()[0].datas;
			this.dataArr = this.common.cGetDeviceData();
		}
	}
</script>

<style lang="scss">
	@import '../../assets/iconfont/icon_dot.css';

	#deviceConfig {
		.c-container-box {
			margin-top: 0;

			// 配置详情
			.config-info {
				$colors: $color1, $color2, $color3;

				@each $c in $colors {
					$i: index($colors, $c);

					.module:nth-child(#{$i}) {
						margin: 20px;
						margin-top: 50px;

						&:first-child {
							margin-top: 20px;
						}

						.title {
							display: flex;
							align-items: center;

							i {
								color: $c;
							}

							span {
								margin-left: 10px;
								font-weight: bold;
							}
						}

						.data {
							margin: 20px;
						}

						.data-led {
							label {
								margin-left: 40px;

								&:first-child {
									margin-left: 0;
								}

								span {
									font-weight: bold;
									font-size: 14px;
								}
							}
						}
					}
				}
			}
		}

		// 超标配置
		.limit-dialog {

			.item {
				width: 100%;
				margin-top: 40px;

				&:first-child {
					margin-top: 0;
				}

				.item-limit {
					display: flex;
					flex-direction: row;
					align-items: center;
					margin: 10px 0;

					label {
						margin-right: 40px;
					}

					.el-input,
					.el-input--medium,
					.el-input__inner {
						width: 120px !important;
					}
				}

				.c-item {
					margin: 10px 0;
				}
			}
		}

		.led-dialog,
		.limit-dialog {

			padding: 0 12%;

			.el-input--medium,
			.el-input__inner {
				width: 220px !important;
			}
		}
	}
</style>
